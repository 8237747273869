import { EUPageDTO } from '~/pages/eu/types';

const EU: EUPageDTO = {
  title: 'Projekt dofinansowany z Funduszy Europejskich',
  subtitle:
    'Swing Development Sp. z o.o. w partnerstwie z Veo Sp. z.o.o. realizuje projekt dofinansowany z Funduszy Europejskich „Morski system nawigacyjny oparty o rozszerzoną rzeczywistość działający na uszczelnionych okularach AR dla żeglarzy i kapitanów statków”.',
  projectSection: {
    description: [
      'Celem projektu jest opracowanie systemu nawigacji morskiej w oparciu o rzeczywistość rozszerzoną.',
      'Planowane efekty to: zwiększenie bezpieczeństwa ruchu jednostek nawigacyjnych oraz usprawnienie i ułatwienie nawigacji jednostek pływających.',
    ],
    details: [
      {
        label: 'Wartość projektu:',
        value: '2 629 933,96 PLN',
      },
      {
        label: 'Dofinansowanie:',
        value: '1 909 570,10 PLN',
      },
    ],
  },
};

export default EU;
