import React, { useMemo } from 'react';

import EUPageData from '~/data/eu';
import Layout from '~/components/Layout';
import EUProjectLogos from '~/components/EUProjectLogos';

import styles from './EU.module.scss';

const EU = () => {
  const {
    title,
    subtitle,
    projectSection: { description, details },
  } = EUPageData;

  const descriptionParagraphs = useMemo(() => {
    return (
      <>
        {description.map((paragraph) => (
          <p key={paragraph} className={styles.paragraph}>
            {paragraph}
          </p>
        ))}
      </>
    );
  }, [description]);

  const detailsList = useMemo(() => {
    return (
      <>
        {details.map(({ label, value }) => (
          <p key={label} className={styles.details}>
            <span className={styles.label}>{label}</span>
            {value}
          </p>
        ))}
      </>
    );
  }, [details]);

  return (
    <Layout invertNavigation>
      <section className={styles.root}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.paragraph}>{subtitle}</p>
        {descriptionParagraphs}
        {detailsList}
        <div className={styles.logos}>
          <EUProjectLogos fluid />
        </div>
      </section>
    </Layout>
  );
};

export default EU;
